<template>
  <div class="container" style="margin-top:150px;">
    <div class="va-card mb-4">
      <div class="dash_card_body">
        <div class="header_section">
          <div>
            <router-link :to="{ name: 'merchants'}"><i class="glyphicon glyphicon-arrow-left"></i> Back</router-link>
          </div>
        </div>
        <div class="create_items_section">
          <form enctype="multipart/form-data" @submit.prevent="createMerchant">
            <div v-if="errors">
              <div class="alert alert-danger">
                <ul>
                  <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                </ul>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-6">
                <div class="setting_header">
                  <h2>Create Merchant</h2>
                </div>
                <hr>
                <div class="setting_content">
                  <div class="form-group setting_form_grp">
                    <div class="row">
                      <div class="col-md-12">
                        <div id="foodie-loading-cover">
                          <span>
                            <label data-baseweb="form-control-label">Logo</label>
                          </span>
                          <div data-baseweb="form-control" class="al dw">
                            <div class="image_div">
                              <div class="img_preview" style="">
                                <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                  <img class="image_responsive" v-if="images" :src="images" />
                                </div>
                              </div>
                              <div class="image_content">
                               

                                <div class="image_button">
                                  
                                  <input type="file" style="border:none;" accept="image/*" @change="uploadImage($event,1000,1000)" id="file-input" class="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="foodie-loader"> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group setting_form_grp">
                    <div class="row">
                      <label class="control-label col-sm-5" for="name">name:</label>
                      <div class="col-sm-7">
                        <div>
                          <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                        </div>
                        <div class="input_error" v-if="v$.name.$error">
                          <span v-if="v$.name.required.$invalid">Name is required</span>
                        </div>

                           
                      </div>
                    </div>
                  </div>
                  <div class="form-group setting_form_grp">
                    <div class="row">
                      <label class="control-label col-sm-5" for="email">Pos Name:</label>
                      <div class="col-sm-7">
                        <input type="text" class="form-control" v-model="posName" @blur="v$.posName.$touch"/>
                        <div class="input_error" v-if="v$.posName.$error">
                          <span v-if="v$.posName.required.$invalid">Pos Name is required</span>
                        </div>
                      </div>
                          
                    </div>
                  </div>

                  <div class="form-group setting_form_grp">
                    <div class="row">
                      <label class="control-label col-sm-5" for="pos_url">Status</label>
                      <div class="col-sm-7">
                        <select name="" class="form-control" v-model="status">
                          <option value="1">Published</option>
                          <option value="0">Unpublished</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group setting_form_grp">
                    <div class="row">
                      <label class="control-label col-sm-5" for="pos_url">Merchant Group</label>
                      <div class="col-sm-7">
                        <select name="" class="form-control" v-model="groupID">
                          <option value="">--select mechant group---</option>
                          <option v-for="group in groups" :key="group.id" :value="group.id">{{group.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- sms settings -->
                </div>
                <hr>
                <div>
                  <div class="setting_header">
                    <h2>SMS Settings</h2>
                  </div>
                  <hr>
                  <div class="setting_content">

                    <div class="form-group setting_form_grp">
                      <div class="row">
                        <label class="control-label col-sm-5" for="pos_url">Sms Gateway:</label>
                        <div class="col-sm-7">
                          <select name="" class="form-control" v-model="smsGateWay" id="">
                            <option value="">Select Sms Gateway</option>
                            <option value="africaIstalking">AfricaIsTalking</option>
                            <option value="bongasms">BongaSms</option>
                            <option value="infobip">Infobip</option>
                            <option value="echomobile">Echomobile</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div >
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="merchant_name">Username:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="smsuserName" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="aut_posting"> Password:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="smsPassword" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="merchant_name">Key:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="smsKey" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="merchant_name">Token:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="smsToken" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="merchant_name">Notification Mask:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="notifiactionMask" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="aut_posting"> Secret:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="smsSecret" class="form-control">
                          </div>
                        </div>
                      </div>

                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="aut_posting"> Base URL:</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="ApibaseUrl" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                   

                  </div>
                </div>
                <hr>
                <!--  -->
                <!-- loyalty or voucher addition -->
                <div>
                  <div class="setting_header">
                    <h2>Loyalty/Voucher Settings</h2>
                  </div>
                  <hr>
                  <div class="setting_content">
                    
                    <div class="form-group setting_form_grp">
                      <div class="row">
                        <label class="control-label col-sm-5" for="pos_url">Setup Program:</label>

                        <div class="col-sm-7">
                          <label class="radio-inline">
                            <input type="radio"  v-model="program" :value="1">&nbsp; Yes 
                          </label> &nbsp;&nbsp;
                          <label class="radio-inline">
                            <input type="radio" v-model="program" :value="0"> &nbsp; No</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="program">

                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="page_title">Select Program Type (loyalty/voucher):</label>
                          
                          <div class="col-sm-7">
                            <label class="radio-inline">
                              <input type="radio"  v-model="programType" value="all">&nbsp; All 
                            </label> 
                            <label class="radio-inline">
                              <input type="radio"  v-model="programType" value="loyalty">&nbsp; Loyalty 
                            </label> 
                            <label class="radio-inline">
                              <input type="radio" v-model="programType" value="voucher"> &nbsp; Voucher</label>
                           
                          </div>
                        </div>
                      </div>

                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="page_title">Voucher Prefix: <small>(3 words maximum)</small> </label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" v-model="voucherPrefix" placeholder="Enter Prefix e.g ART">
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="page_title">Country Code:</label>
                          <div class="col-sm-7">
                            <select class="form-control" v-model="countryCode" >
                              <option v-for="(country, cindex) in countryCodesCurrency" :key="cindex" :value="country.code">{{country.code}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="currency_symbol">Currency:</label>
                          <div class="col-sm-7">
                            <select class="form-control" v-model="currency" >
                              <option v-for="(currency, cindex) in countryCodesCurrency" :key="cindex" :value="currency.currency">{{currency.currency}}</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="email">Setup:</label>
                          <div class="col-sm-7">
                            <label class="radio-inline">
                              <input v-model="setup" type="radio" :value="1" >&nbsp; Live 
                            </label> &nbsp;&nbsp;
                            <label class="radio-inline">
                              <input v-model="setup" type="radio"  :value="0"> &nbsp; Demo
                            </label>
                          </div>
                        </div>
                      </div>
                      <div v-if="setup">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="lv_giftvoucher_url">Live Url:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="liveUrl" placeholder="Enter Url">
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="lv_giftvoucher_username">Live Username:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="liveUsername" placeholder="Enter  Username">
                            </div>
                          </div>
                        </div>

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="lv_giftvoucher_password">Live Password:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="livePassword" placeholder="Enter Password">
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  -->

                      <!--  -->
                      <div v-if="!setup">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_giftvoucher_url">Demo Url:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="demoUrl" placeholder="Enter Url">
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_giftvoucher_username">Demo  Username:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="demoUsername" placeholder="Enter  Username" name="email">
                            </div>
                          </div>
                        </div>

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_giftvoucher_password">Demo Password:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="demoPassword" placeholder="Enter Password" name="email">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
              <!--  -->
            </div>
            <div class="row">
              <div class="col-md-12">
                <button type="submit" class="btn btn-primary"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
              </div>
            </div>
            <!-- end row -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'

const toast = useToast();
export default {
    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            voucherPrefix: '',
            name: '',
            posName: '',
            images: '',
            errors: false,
            imageUrl: '',
            options: [],
            spinner: false,
            merchants: [],
            errorMsg:[],
            brands: [],
            groups:[],
            groupID: '',
            setup: 1,
            liveUrl:'',
            ApibaseUrl: '',
            liveUsername: '',
            livePassword: '',
            programType:'voucher',
            demoUrl: '',
            demoUsername: '',
            demoPassword: '',
            program: 0,
            smsPassword:'',
            smsuserName:'',
            smsGateWay: '',
            smsKey: '',
            smsSecret: '',
            merchantGroups: [],
            countryCode: 'KE',
            status: 1,
            currency: 'KES',
            smsToken:'',
            notifiactionMask:'',
            countryCodesCurrency: [
                {
                    'code': 'KE',
                    'currency': 'KES'
                },
                {
                    'code': 'UG',
                    'currency':'UGX'
                },
                {
                    'code': 'TZ',
                    'currency':'TZS'
                },
                {
                  "code": "RW",
                  'currency': "RWF"
                }
            ],
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."

        }
    },
    created:  async function () {
        this.getMerchantGroups();
    },
   
    validations() {
        return {
            name: { required },
            posName: { required },
        }
    },
    methods: {
        async createMerchant() {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let smsGatewayConfig = {};
            if(this.smsuserName !== ''){
               smsGatewayConfig = {
                'username': this.smsuserName,
                'apiKey': this.smsPassword
              };
            }else if (this.smsKey !=='' && this.smsSecret !=='') {
              smsGatewayConfig = {
                'key': this.smsKey,
                'secret': this.smsSecret
              };
            }else if(this.smsToken !=''){
              smsGatewayConfig = {
                'token':this.smsToken,
                'notification_mask':this.notifiactionMask
              }
            } else {
               smsGatewayConfig = {
                'key': this.smsKey,
                'base_url': this.ApibaseUrl
              };
            }
            const url = `${this.dashBaseUrl}/create-merchants`;
            const payload = {
                'name': this.name,
                'pos_name': this.posName,
                'status': this.status,
                'logo': this.imageUrl,
                'group_id': this.groupID,
                'sms_gateway': this.smsGateWay,
                'sms_gateway_config':JSON.stringify(smsGatewayConfig),
                'set_program': this.program,
                'setup':this.setup,
                'live_api_url':this.liveUrl,
                'live_api_username': this.liveUsername,
                'live_api_password': this.livePassword,
                'demo_api_url': this.demoUrl,
                'demo_api_username': this.demoUsername,
                'demo_api_password':this.demoPassword,
                'country_code':this.countryCode,
                'currency':this.currency,
                'demoPassword': this.demoPassword,
                'program_type': this.programType,
                'voucher_prefix': this.voucherPrefix
            }
            this.axios.post(url,payload,config).then((response)=>{
                if(response.status === 200){
                    this.spinner = false;
                    this.$router.push({
                        name: 'merchants'
                    });
                    toast.success('Merchant created successfully');
                }
            }).catch((error)=>{
                this.spinner =false;
                if(error.response.status === 400){
                  this.errors = true;
                    this.errorMsg = error.response.data.errors
                }
            });

        },
        async getMerchantGroups() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchant-groups`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.groups = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            });

        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
